import * as React from "react";
import { send } from "../../helpers/requests";
import { tr } from "../../helpers/languages";
import userManager from "../../util/userManager";
import { formatDistanceStrict } from "date-fns";
import RenderCard from "./RenderCards";
import Popup from "../common/popup";
import DashSettings from "./settings";
import Help from "../common/help/help";
import { ClassicDashboard, DefaultDashboard, PreVersionElevenMigration, PreviewDashboard } from "./DefaultDashboardLayouts";
import "./Dashboard.css";
import SettingsIcon from "../../assets/icons/settings.svg";
import CommentsIcon from "../../assets/icons/comment.svg";
import ClockIcon from "../../assets/icons/clock.svg";
import TimeFrame from "./timeFrame/TimeFrame";
import { Spinner } from "../common/ui";
import { Convert } from "../common/ValidateDashboardLayout";
import { LatestVersion } from "../common/changelog/versions";
import Scroll from "react-scroll";
import Comments from "./ui/Comments/Comments";
import { SetDefaultLocalStorage } from "./SetDefaultLocalStorage";
import { LogError } from "../analytics/eventTracker";

/**
 * @param {string | any[] | null} str
 */
function TryParseInt(str) {
    var retValue = 0;
    if (str !== null) {
        if (str.length > 0) {
            if (!isNaN(str)) {
                retValue = parseInt(str);
            }
        }
    }
    return retValue;
}
const sleep = (/** @type {number} */ ms) => new Promise((resolve) => setTimeout(resolve, ms));

// type Customer = {
//     Name: string,
//     CustID: string,
//     allowRemoteAgent?: boolean
// }
// type LastCustomer = {
//     customer_id: string,
//     organisation_name: string,
//     allowRemoteAgent?: boolean
// }

// type DashboardProps = {};
// type DashboardState = {
//     startDate: Date,
//     dash: DashboardCard[],
//     endDate: Date,
//     popupStatus: boolean,
//     popupType: string,
//     helpPopupSection: string,
//     initialCustomerID: string,
//     marquee: number,
//     cardsLoaded: number,
//     isLoading: boolean,
//     CustomerName: Customer
//     marqueeCardKey: number
// };

export default class Dashboard extends React.PureComponent {
    _isMounted = false; //If true then component is mounted. If false data returned from API GET should be dropped.

    constructor(props) {
        super(props);
        this.state = {
            dash: [],
            startDate: new Date(new Date()),
            endDate: new Date(new Date(new Date().setDate(new Date().getDate() - 7))),
            popupStatus: false,
            popupType: "",
            helpPopupSection: "",
            initialCustomerID: "",
            marquee: null,
            cardsLoaded: 0,
            isLoading: true,
            CustomerName: {
                Name: "",
                CustID: "",
                allowRemoteAgent: true
            },
            marqueeCardKey: 0
        };
    }
    /**
     * componentDidMount
     * @description API calls for rebuilding base dashboard
     */
    async componentDidMount() {
        this._isMounted = true;
        SetDefaultLocalStorage();
        await this.getCustomerName();
        sessionStorage.setItem("startDate", new Date(new Date()).toString());
        sessionStorage.setItem("endDate", new Date(new Date(new Date().setDate(new Date().getDate() - 7))).toString());
        this.getDashboard();
        const marquee = this.getMarqueeMode();
        if (marquee) {
            if (TryParseInt(marquee)) {
                this.runMarqueeMode(marquee);
            }
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.marqueeAutoRefreshInterval);
    }
    getMarqueeMode = () => {
        const marqueeVal = window.localStorage.getItem("marquee");
        if (marqueeVal) {
            this.setState({ marquee: marqueeVal });
            return marqueeVal;
        }
        return null;
    };
    runMarqueeMode = (marquee) => {
        var scroll = Scroll.animateScroll;
        var marqueeAutoRefreshInterval;
        scroll.scrollToBottom({
            duration: 60000,
            smooth: true
        });
        var marqueeInterval = parseInt(marquee) * 60 * 1000; //Convert to int + milliseconds
        marqueeAutoRefreshInterval = setInterval(() => {
            window.scrollTo({ top: 0, behavior: "auto" });
            this.setState({ marqueeCardKey: this.state.marqueeCardKey + 1 }, () => {
                scroll.scrollToBottom({
                    duration: marqueeInterval,
                    smooth: true
                });
            });
        }, marqueeInterval);
    };
    ///
    /// API Calls
    ///
    /**
     * getCustomerID
     * @description Gets the Customer_id of the authenticated user
     */
    getCustomerID = async () => {
        let customerid = (await userManager.getUser()).profile["http://schemas.cse-net.co.uk/ws/2014/02/identity/claims/customerid"];
        return customerid;
    };
    /**
     * getAccessToken
     * @description Gets the access token of the authenticated user
     */
    getAccessToken = async () => {
        let token = (await userManager.getUser()).access_token;
        return token;
    };
    /**
     * getCustomerName
     * @description Gets CustomerName and sets this.state.CustomerName.Name
     */
    getCustomerName = async () => {
        let CustID = await this.getCustomerID();
        let lastCustomer = localStorage.getItem("lastCustomer");
        if (lastCustomer !== null && lastCustomer !== undefined && lastCustomer !== "") {
            lastCustomer = JSON.parse(lastCustomer);
            this.setState({
                initialCustomerID: CustID,
                CustomerName: {
                    Name: lastCustomer.organisation_name,
                    CustID: lastCustomer.customer_id,
                    allowRemoteAgent: lastCustomer.allowRemoteAgent !== undefined ? lastCustomer.allowRemoteAgent : true
                }
            });
            sessionStorage.setItem("custID", lastCustomer.customer_id);
            return;
        }
        send({
            method: "GET",
            url: `/api/reporting/customername`,
            token: await this.getAccessToken(),
            data: "",
            params: ""
        })
            .then((response) => {
                if (this._isMounted) {
                    this.setState({
                        initialCustomerID: CustID,
                        CustomerName: {
                            ...this.state.CustomerName,
                            Name: response.data.organisation_name,
                            CustID: CustID,
                            allowRemoteAgent: response.data.allowRemoteAgent
                        }
                    });
                    sessionStorage.setItem("custID", CustID);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    ///
    /// Other
    ///
    getLayout = async () => {
        var res = await send({
            method: "GET",
            url: "/api/reporting/getlayout",
            token: await this.getAccessToken(),
            data: "",
            params: ""
        });
        return res.data;
    };
    getDashboard = async () => {
        var dash = null;
        try {
            dash = JSON.stringify(await this.getLayout());
            if (dash !== null) {
                Convert.toDashboardLayout(dash); //validate JSON
            }
            dash = JSON.parse(dash);
        } catch (error) {
            LogError("Error loading remote layout", { error, customer: this.state.CustomerName });
            console.error(error);
        }
        if (dash === null) {
            //If dash is not array then error would raise, this stops that error.
            dash = DefaultDashboard;
        } else if (dash[0].dashLayout === 0) {
            //Default
            dash = DefaultDashboard;
        } else if (dash[0].dashLayout === 1) {
            //Classic
            dash = ClassicDashboard;
        } else if (dash[0].dashLayout === 2) {
            //Preview
            dash = PreviewDashboard;
        } else if (dash[0].dashLayout === 99) {
        } //Don't set dash, use custom loaded dash. Not the prettiest but does the job :)
        else {
            dash = DefaultDashboard;
        }
        //Perform migration process NOTE: must be completed after isNull check
        dash = this.MigrateDashboardToLatest(dash);
        this.setState({ dash, isLoading: false });
    };
    MigrateDashboardToLatest = (/** @type import("../common/ValidateDashboardLayout").DashboardLayout[] */ dash) => {
        // @ts-ignore
        if (dash[0].version === LatestVersion()) {
            return dash; //Latest, no migration required
        }
        if (dash[0].dashLayout === 2 && PreVersionElevenMigration.includes(dash[0].version)) {
            //Version 2022.1.0.11 moved custom from dashLayout === 2 to dashLayout === 99 to allow room for more pre-defined layouts (preview)
            dash[0].dashLayout = 99;
        }
        // @ts-ignore
        if (dash[0].dashLayout === 2 && dash[0].version.split(".")[0] < 2024) {
            dash[0].version = LatestVersion();
            dash.push({ cardItem: "ServiceDeskFeedback", cardType: 0, dashLayout: null, version: null, _id: null, updated: null });
        }
        return dash;
    };
    setDashboard = (newDash) => {
        var dash = window.localStorage.getItem("dashboard");
        if (dash !== newDash) {
            window.localStorage.setItem("dashboard", JSON.stringify(newDash));
        }
    };
    /**
     * OpenPopup
     * @param {string} type Popup section to "jump" to
     */
    OpenPopup = (type) => {
        this.setState({
            popupStatus: true,
            popupType: "help",
            helpPopupSection: type
        });
    };
    /**
     * UpdateCustomerName
     * @description Re-marks all UI elements as getDone: false and calls all Dashboard API's with the updated customer_id
     * @param {object} newCustomer DropDown Selection Int (AllCustomerName index)
     * @param {string} newCustomer.organisation_name Name of organisation
     * @param {string} newCustomer.customer_id Customer ID
     * @param {boolean} newCustomer.allowRemoteAgent Boolean, allow remote agent
     */
    UpdateCustomerName = (newCustomer) => {
        if (newCustomer === undefined || newCustomer === "") {
            return;
        }
        localStorage.setItem("lastCustomer", JSON.stringify(newCustomer));
        this.setState({
            CustomerName: {
                Name: newCustomer.organisation_name,
                CustID: newCustomer.customer_id,
                allowRemoteAgent: newCustomer.allowRemoteAgent
            }
        });
        sessionStorage.setItem("custID", newCustomer.customer_id);
    };
    /**
     * UpdateTimeFrame
     * @description Updates startDate and endDate to select time frame.
     * @param {Date} startDate Time frame start date
     * @param {Date} endDate Time frame end date
     */
    UpdateTimeFrame = async (startDate, endDate) => {
        if (this.state.startDate !== startDate || this.state.endDate !== endDate) {
            this.setState({
                startDate: startDate,
                endDate: endDate,
                popupStatus: false
            });
            sessionStorage.setItem("startDate", startDate.toString());
            sessionStorage.setItem("endDate", endDate.toString());
        }
    };
    /**
     * UpdateLayoutArr
     * @description Updates layoutArr which defines which UI cards to render
     * @param {JSON} dash [{"cardItem":"ServerStatus","cardType":1,"dashLayout":0},{"cardItem":"ServerThreat","cardType":1}]
     */
    UpdateLayoutArr = (dash) => {
        var dashToBeSet = [];
        if (dash !== this.state.dash) {
            if (dash[0].dashLayout === 0) {
                dashToBeSet = DefaultDashboard;
            } else if (dash[0].dashLayout === 1) {
                dashToBeSet = ClassicDashboard;
            } else if (dash[0].dashLayout === 2) {
                dashToBeSet = PreviewDashboard;
            } else {
                dashToBeSet = dash;
            }
            this.setState({ dash: dashToBeSet });
            this.setDashboard(dashToBeSet);
        }
    };
    ClosePopup = () => {
        if (this.state.popupStatus === true) {
            this.setState({ popupStatus: false });
        }
    };
    FormatTimeFrameText = () => {
        let text = "";
        if (
            `${this.state.startDate.getDate()} ${this.state.startDate.getMonth()} ${this.state.startDate.getFullYear()}` ===
            `${this.state.endDate.getDate()} ${this.state.endDate.getMonth()} ${this.state.endDate.getFullYear()}`
        ) {
            text = "1 day";
        } else {
            text = formatDistanceStrict(this.state.startDate, this.state.endDate, {
                unit: "day" || "month"
            });
        }
        return <p style={{ whiteSpace: "nowrap", margin: "auto 5px auto 10px" }}>{text}</p>;
    };
    CardRendered = () => {
        this.setState({ cardsLoaded: this.state.cardsLoaded + 1 });
    };
    render() {
        if (this.state.isLoading) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10rem"
                    }}
                >
                    <Spinner size={250} />
                </div>
            );
        }
        var content = <></>;
        switch (this.state.popupType) {
            case "help":
                content = <Help helpSection={this.state.helpPopupSection} />;
                break;
            case "settings":
                content = (
                    <DashSettings
                        UpdateCustomerName={this.UpdateCustomerName}
                        dash={this.state.dash}
                        Customer={this.state.CustomerName}
                        initialCustID={this.state.initialCustomerID}
                        pushDashType={this.UpdateLayoutArr}
                    />
                );
                break;
            case "timeFrame":
                content = <TimeFrame sendNewTimeFrame={this.UpdateTimeFrame} startDate={this.state.startDate} endDate={this.state.endDate} />;
                break;
            case "comments":
                content = (
                    <Comments
                        type={"All"}
                        custID={this.state.CustomerName.CustID || this.state.initialCustomerID}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                    />
                );
                break;
            default:
                content = <></>;
                break;
        }
        var timeFrameText = this.FormatTimeFrameText();
        return (
            <>
                <div className="header-container">
                    <div className="header-item header-customer-id header-flex-center">
                        {/* {this.state.marquee && (
                            <div style={{ marginRight: "20px" }}>
                                <img
                                    alt=""
                                    src={CSELogo}
                                    style={{ height: "80px", width: "80px", marginLeft: "25px" }}
                                    className="header-item"
                                    aria-label="CSE-Logo"
                                />
                            </div>
                        )} */}
                        <h3 id="custNameHeader">{this.state.CustomerName.Name}</h3>
                    </div>
                    <div className="headerContainer">
                        <div
                            className="helpIconContainer"
                            onClick={() => {
                                this.setState({
                                    popupStatus: !this.state.popupStatus,
                                    popupType: "timeFrame"
                                });
                            }}
                        >
                            <img className="helpIcon" src={ClockIcon} alt="" aria-label={tr("DASHBOARD_FILTER_BY")} />
                            {timeFrameText}
                        </div>
                        {this.state.initialCustomerID === "CSE-NET" && (
                            <div
                                className="settingsIconContainer"
                                onClick={() =>
                                    this.setState({
                                        popupStatus: true,
                                        popupType: "comments"
                                    })
                                }
                            >
                                <img src={CommentsIcon} className="settingsIcon" alt="" aria-label={tr("DASHBOARD_COMMENTS")}></img>
                            </div>
                        )}
                        <div
                            className="settingsIconContainer"
                            onClick={() =>
                                this.setState({
                                    popupStatus: true,
                                    popupType: "settings"
                                })
                            }
                        >
                            <img src={SettingsIcon} className="settingsIcon" alt="" aria-label={tr("DASHBOARD_SETTINGS")}></img>
                        </div>
                    </div>
                </div>
                <div id="dash-container" className="dash-container">
                    {this.state.dash.map((dashCard, index) => {
                        return (
                            <RenderCard
                                key={`card ${index} ${this.state.marqueeCardKey}`}
                                cardName={dashCard.cardItem}
                                cardType={dashCard.cardType}
                                custID={this.state.CustomerName.CustID}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                cardRendered={this.CardRendered}
                                disableReRender={false}
                                isCSE={this.state.initialCustomerID === "CSE-NET"}
                                raOnly={!this.state.CustomerName.allowRemoteAgent}
                                OpenHelpPopup={this.OpenPopup}
                            />
                        );
                    })}
                </div>
                {this.state.popupStatus && (
                    <Popup
                        content={content}
                        close={() => {
                            this.setState({ popupStatus: false });
                        }}
                        PopupName={this.state.popupType}
                    />
                )}
            </>
        );
    }
}
