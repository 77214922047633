import * as React from "react";
import userManager from "../../../util/userManager";
import { send } from "../../../helpers/requests";
import DataTable from "react-data-table-component";
import { Input, Label } from "reactstrap";
import { tr } from "../../../helpers/languages";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import { Spinner } from "../../common/ui";

import styles from "./popout.module.css";

const hotFixDateSort = (a, b) => {
    var valueA = new Date(a.timeStamp);
    var valueB = new Date(b.timeStamp);
    const aValue = JSON.parse(a.value);
    const bValue = JSON.parse(b.value);
    if (Object.keys(aValue).includes("installedOn")) {
        valueA = new Date(aValue.installedOn);
    }
    if (Object.keys(bValue).includes("installedOn")) {
        valueB = new Date(bValue.installedOn);
    }
    if (valueA < valueB) {
        return 1;
    }
    if (valueA > valueB) {
        return -1;
    }
    return 0;
};

const hotFixColumns = [
    {
        name: "Server Name",
        selector: (row) => row.hostName,
        sortable: true,
        reorder: true
    },
    {
        name: "Update Type",
        selector: (row) => row.type,
        reorder: true
    },
    {
        name: "KB",
        selector: (row) => {
            if (row.kb.toLowerCase().includes("kb")) {
                return row.kb;
            } else {
                return "";
            }
        },
        sortable: true,
        reorder: true
    },
    {
        name: "Microsoft Update Catalogue",
        cell: (row) => {
            try {
                const value = JSON.parse(row.value);
                if (Object.keys(value).includes("kbArticles")) {
                    if (value.kbArticles.length !== 0) {
                        return (
                            <a
                                target="_blank"
                                style={{ color: "#fff", textDecoration: "underline" }}
                                href={`https://www.catalog.update.microsoft.com/Search.aspx?q=KB${value.kbArticles[0]}`}
                            >
                                Click here
                            </a>
                        );
                    }
                    return <></>;
                } else if (Object.keys(value).includes("hotFixID")) {
                    if (value.hotFixID.toLowerCase().includes("kb")) {
                        return (
                            <a
                                target="_blank"
                                style={{ color: "#fff", textDecoration: "underline" }}
                                href={`https://www.catalog.update.microsoft.com/Search.aspx?q=${value.hotFixID}`}
                            >
                                Click here
                            </a>
                        );
                    }
                }
                return <></>;
            } catch (error) {
                return <></>;
            }
        },
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        name: "Date",
        selector: (row) => {
            const value = JSON.parse(row.value);
            if (Object.keys(value).includes("installedOn")) {
                return new Date(value.installedOn).toUTCString();
            } else if (row.timeStamp === "Unknown") {
                return "Unknown";
            } else if (row.timeStamp === null) {
                return "---";
            } else {
                return new Date(row.timeStamp).toUTCString();
            }
        },
        sortable: true,
        sortFunction: (a, b) => hotFixDateSort(a, b),
        reorder: true
    },
    {
        name: "Site",
        selector: (row) => row.site,
        sortable: true,
        reorder: true
    }
];

const patchColumns = [
    {
        name: "Server Name",
        selector: (row) => row.hostName,
        sortable: true,
        reorder: true
    },
    {
        name: "Update Title",
        selector: (row) => row.title,
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        name: "Update Description",
        cell: (row) => row.description,
        wrap: true,
        sortable: true,
        reorder: true
    },
    {
        name: "Microsoft Update Catalogue",
        cell: (row) => {
            try {
                //
                if (row.kb === null) {
                    return <></>;
                }
                if (row.kb.length === 0) {
                    return <></>;
                }
                return (
                    <a
                        target="_blank"
                        style={{ color: "#fff", textDecoration: "underline" }}
                        href={`https://www.catalog.update.microsoft.com/Search.aspx?q=KB${row.kb[0]}`}
                    >
                        Click here
                    </a>
                );
            } catch (error) {
                return <></>;
            }
        },
        wrap: true,
        sortable: true,
        reorder: true
    },
    // {
    //     name: "CVE",
    //     selector: (row) => row.cve,
    //     wrap: true,
    //     sortable: true,
    //     reorder: true
    // },
    {
        name: "Installed Date",
        selector: (row) => {
            if (row.timeStamp === "Unknown") {
                return "Unknown";
            } else if (row.timeStamp === null) {
                return "---";
            } else {
                return new Date(row.timeStamp).toUTCString();
            }
        },
        sortable: true,
        reorder: true
    },
    {
        name: "Site",
        selector: (row) => row.site,
        sortable: true,
        reorder: true
    }
];

export default class ServerUpdateInstalledPopout extends React.PureComponent {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            getDone: false,
            curSite: "All",
            search: "",
            updates: []
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.getWindowsUpdateStats(this.props.customerID, this.props.startDate, this.props.endDate);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    //API Calls
    getAccessToken = async () => {
        let token = (await userManager.getUser()).access_token;
        return token;
    };
    getWindowsUpdateStats = async (custID, startDate, endDate) => {
        const timeZoneOffset = startDate.getTimezoneOffset() * 60;
        const newStartDate = Math.floor(startDate.getTime() / 1000) - timeZoneOffset;
        const newEndDate = Math.floor(endDate.getTime() / 1000) - timeZoneOffset;
        send({
            method: "GET",
            url: `/api/reporting/getserverupdatesinstalled?custid=${custID}&startdate=${newStartDate}&enddate=${newEndDate}`,
            token: await this.getAccessToken()
        }).then((response) => {
            if (this._isMounted) {
                this.props.popoutRendered();
                this.setState({ getDone: true, updates: response.data });
            }
        });
    };
    //Extra
    ChangeSite = (newSite) => {
        this.setState({ curSite: newSite.target.value });
    };
    CreateSiteList = () => {
        var uniqueSites = [];
        this.state.updates.forEach((station) => {
            if (!uniqueSites.includes(station.site)) {
                uniqueSites.push(station.site);
            }
        });
        return uniqueSites.sort();
    };
    CreateFinalServerPatchList = () => {
        var finalServerList = [];
        if (this.state.search === "") {
            if (this.state.curSite !== "All") {
                this.state.updates.forEach((server) => {
                    if (server.type === "Patch") {
                        if (server.site === this.state.curSite) {
                            finalServerList.push(server);
                        }
                    }
                });
            } else {
                finalServerList = JSON.parse(JSON.stringify(this.state.updates.filter((update) => update.type === "Patch")));
            }
        } else {
            if (this.state.curSite !== "All") {
                this.state.updates.forEach((server) => {
                    if (server.type === "Patch") {
                        if (server.site === this.state.curSite) {
                            if (server.hostName.toLowerCase().includes(this.state.search)) {
                                finalServerList.push(server);
                            }
                        }
                    }
                });
            } else {
                this.state.updates.forEach((server) => {
                    if (server.type === "Patch") {
                        if (server.hostName !== undefined && server.hostName !== null) {
                            if (server.hostName.toLowerCase().includes(this.state.search)) {
                                finalServerList.push(server);
                            }
                        }
                    }
                });
            }
        }
        return finalServerList;
    };
    CreateFinalServerHotfixList = () => {
        var finalServerList = [];
        if (this.state.search === "") {
            if (this.state.curSite !== "All") {
                this.state.updates.forEach((server) => {
                    if (server.type === "Hotfix") {
                        if (server.site === this.state.curSite) {
                            finalServerList.push(server);
                        }
                    }
                });
            } else {
                finalServerList = JSON.parse(JSON.stringify(this.state.updates.filter((update) => update.type === "Hotfix")));
            }
        } else {
            if (this.state.curSite !== "All") {
                this.state.updates.forEach((server) => {
                    if (server.type === "Hotfix") {
                        if (server.site === this.state.curSite) {
                            if (server.hostName.toLowerCase().includes(this.state.search)) {
                                finalServerList.push(server);
                            }
                        }
                    }
                });
            } else {
                this.state.updates.forEach((server) => {
                    if (server.type === "Hotfix") {
                        if (server.hostName !== undefined && server.hostName !== null) {
                            if (server.hostName.toLowerCase().includes(this.state.search)) {
                                finalServerList.push(server);
                            }
                        }
                    }
                });
            }
        }
        return finalServerList.sort(hotFixDateSort);
    };
    render() {
        var uniqueSites = this.CreateSiteList();
        var finalUpdateList = JSON.parse(JSON.stringify(this.state.updates));
        if (!this.state.getDone) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10rem"
                    }}
                >
                    <Spinner size={250} />
                </div>
            );
        }
        return (
            <div className={styles.popoutContainer}>
                <div>
                    <h2 className={styles.popoutHeader}>{tr("DASHBOARD_WIN_UPDATE_INSTALLED_TITLE")}</h2>
                </div>
                <div className={styles.popoutSearch}>
                    <div className="form-floating-labels">
                        <Input
                            id="Search"
                            placeholder=" "
                            value={this.state.search || ""}
                            onChange={(v) => {
                                this.setState({
                                    search: v.target.value.toLowerCase()
                                });
                            }}
                        />
                        <Label for="Search">{tr("DASHBOARD_SEARCH_POPOUT_SERVERNAMES")}</Label>
                    </div>
                </div>
                <div className={styles.popoutExport}>
                    <div className="perm-floating-label">
                        <Input className={styles.popoutInput} type="select" id="winUpdateSiteSelect" onChange={this.ChangeSite}>
                            <option value="All">{tr("ALL")}</option>
                            {uniqueSites.map((site, key) => {
                                return (
                                    <option key={key} value={site}>
                                        {site}
                                    </option>
                                );
                            })}
                        </Input>
                        <Label for="winUpdateSiteSelect">{tr("DASHBOARD_SITE_SELECT")}</Label>
                    </div>
                    <CSVLink
                        className={styles.exportCSVButton}
                        data={finalUpdateList}
                        filename={"export " + dayjs().format("DD-MM-YYYY h-mm-ss") + ".csv"}
                    >
                        {tr("EXPORT_CSV")}
                    </CSVLink>
                </div>
                <div>
                    <DataTable columns={patchColumns} data={finalUpdateList} theme={this.props.theme} pagination />
                </div>
            </div>
        );
    }
}
