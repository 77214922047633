import { LatestVersion } from "../common/changelog/versions";

export type DashboardCard = {
    cardItem: string,
    cardType: number,
    dashLayout?: number,
    version?: string
};

export const DefaultDashboard: DashboardCard[] = [
    {
        cardItem: "ServerStatus",
        cardType: 2,
        dashLayout: 0,
        version: LatestVersion()
    },
    { cardItem: "ServerThreat", cardType: 1 },
    { cardItem: "ServerVolStorage", cardType: 1 },
    { cardItem: "ServerLogin", cardType: 0 },
    { cardItem: "ServerWinUpdates", cardType: 1 },
    { cardItem: "ServerWinUpdatesInstalled", cardType: 0 },
    { cardItem: "Backups", cardType: 1 },
    { cardItem: "UnexpectedServerShutdown", cardType: 0 },
    { cardItem: "ServerRebootRequired", cardType: 0 },
    { cardItem: "CSERemoteAgent", cardType: 1 },
    { cardItem: "AppUsage", cardType: 0 },
    { cardItem: "NetworkDeviceStatus", cardType: 0 },
    { cardItem: "NetworkSwitchDeviceStatus", cardType: 0 },
    { cardItem: "NetworkWAPDeviceStatus", cardType: 0 },
    { cardItem: "StationsLastBooted", cardType: 1 },
    { cardItem: "StationThreatStatus", cardType: 2 },
    { cardItem: "StationVolStorage", cardType: 2 },
    { cardItem: "StationWindowsElevenReadiness", cardType: 0 },
    { cardItem: "LaptopBatteryHealth", cardType: 0 },
    { cardItem: "StationLoginInfo", cardType: 0 },
    { cardItem: "ServiceDeskStats", cardType: 0 },
    { cardItem: "ServiceDeskTrends", cardType: 0 },
    { cardItem: "ServiceDeskOpenRequests", cardType: 0 },
    { cardItem: "ServiceDeskClosedRequests", cardType: 0 },
    { cardItem: "ActivityPerUser", cardType: 0 },
    { cardItem: "ServiceDeskPerformance", cardType: 0 }
];

export const ClassicDashboard: DashboardCard[] = [
    {
        cardItem: "ServerStatus",
        cardType: 0,
        dashLayout: 1,
        version: LatestVersion()
    },
    { cardItem: "ServerThreat", cardType: 0 },
    { cardItem: "ServerVolStorage", cardType: 0 },
    { cardItem: "ServerLogin", cardType: 0 },
    { cardItem: "ServerWinUpdates", cardType: 0 },
    { cardItem: "ServerWinUpdatesInstalled", cardType: 0 },
    { cardItem: "Backups", cardType: 0 },
    { cardItem: "UnexpectedServerShutdown", cardType: 0 },
    { cardItem: "ServerRebootRequired", cardType: 0 },
    { cardItem: "CSERemoteAgent", cardType: 0 },
    { cardItem: "AppUsage", cardType: 0 },
    { cardItem: "NetworkDeviceStatus", cardType: 0 },
    { cardItem: "NetworkSwitchDeviceStatus", cardType: 0 },
    { cardItem: "NetworkWAPDeviceStatus", cardType: 0 },
    { cardItem: "StationsLastBooted", cardType: 0 },
    { cardItem: "StationThreatStatus", cardType: 0 },
    { cardItem: "StationVolStorage", cardType: 0 },
    { cardItem: "StationWindowsElevenReadiness", cardType: 0 },
    { cardItem: "LaptopBatteryHealth", cardType: 0 },
    { cardItem: "StationLoginInfo", cardType: 0 },
    { cardItem: "ServiceDeskStats", cardType: 0 },
    { cardItem: "ServiceDeskTrends", cardType: 0 },
    { cardItem: "ServiceDeskOpenRequests", cardType: 0 },
    { cardItem: "ServiceDeskClosedRequests", cardType: 0 },
    { cardItem: "ActivityPerUser", cardType: 0 },
    { cardItem: "ServiceDeskPerformance", cardType: 0 }
];

export const PreviewDashboard: DashboardCard[] = [
    {
        cardItem: "ServerStatus",
        cardType: 2,
        dashLayout: 2,
        version: LatestVersion()
    },
    { cardItem: "ServerThreat", cardType: 1 },
    { cardItem: "ServerVolStorage", cardType: 1 },
    { cardItem: "ServerLogin", cardType: 0 },
    { cardItem: "ServerWinUpdates", cardType: 1 },
    { cardItem: "ServerWinUpdatesInstalled", cardType: 0 },
    { cardItem: "Backups", cardType: 1 },
    { cardItem: "UnexpectedServerShutdown", cardType: 0 },
    { cardItem: "ServerRebootRequired", cardType: 0 },
    { cardItem: "CSERemoteAgent", cardType: 1 },
    { cardItem: "AppUsage", cardType: 0 },
    { cardItem: "NetworkDeviceStatus", cardType: 0 },
    { cardItem: "NetworkSwitchDeviceStatus", cardType: 0 },
    { cardItem: "NetworkWAPDeviceStatus", cardType: 0 },
    { cardItem: "AssetChanges", cardType: 0 },
    { cardItem: "StationsLastBooted", cardType: 1 },
    { cardItem: "StationThreatStatus", cardType: 2 },
    { cardItem: "StationVolStorage", cardType: 2 },
    { cardItem: "StationWindowsElevenReadiness", cardType: 0 },
    { cardItem: "LaptopBatteryHealth", cardType: 0 },
    { cardItem: "StationLoginInfo", cardType: 0 },
    { cardItem: "ServiceDeskStats", cardType: 0 },
    { cardItem: "ServiceDeskSLA", cardType: 0 },
    { cardItem: "ServiceDeskTrends", cardType: 0 },
    { cardItem: "ServiceDeskOpenRequests", cardType: 0 },
    { cardItem: "ServiceDeskClosedRequests", cardType: 0 },
    { cardItem: "ActivityPerUser", cardType: 0 },
    { cardItem: "ServiceDeskPerformance", cardType: 0 },
    { cardItem: "ServiceDeskFeedback", cardType: 1 },
    { cardItem: "ServiceDeskTopKeywords", cardType: 0 },
    { cardItem: "ServiceDeskTopCategories", cardType: 0 },
    // { cardItem: "SamiKBFeedback", cardType: 0 },
    { cardItem: "SamiTokenUsage", cardType: 0 },
    { cardItem: "SamiConversationCount", cardType: 0 }
];

export const CustomDashboard: DashboardCard[] = [
    {
        cardItem: "ServerStatus",
        cardType: 2,
        dashLayout: 99,
        version: LatestVersion()
    },
    { cardItem: "ServerThreat", cardType: 1 },
    { cardItem: "ServerVolStorage", cardType: 1 },
    { cardItem: "ServerLogin", cardType: 0 },
    { cardItem: "ServerWinUpdates", cardType: 1 },
    { cardItem: "Backups", cardType: 1 },
    { cardItem: "UnexpectedServerShutdown", cardType: 0 },
    { cardItem: "ServerRebootRequired", cardType: 0 },
    { cardItem: "CSERemoteAgent", cardType: 1 },
    { cardItem: "AppUsage", cardType: 0 },
    { cardItem: "NetworkDeviceStatus", cardType: 0 },
    { cardItem: "NetworkSwitchDeviceStatus", cardType: 0 },
    { cardItem: "NetworkWAPDeviceStatus", cardType: 0 },
    { cardItem: "StationsLastBooted", cardType: 1 },
    { cardItem: "StationThreatStatus", cardType: 2 },
    { cardItem: "StationVolStorage", cardType: 2 },
    { cardItem: "StationWindowsElevenReadiness", cardType: 0 },
    { cardItem: "LaptopBatteryHealth", cardType: 0 },
    { cardItem: "StationLoginInfo", cardType: 0 },
    { cardItem: "ServiceDeskStats", cardType: 0 },
    { cardItem: "ServiceDeskTrends", cardType: 0 },
    { cardItem: "ActivityPerUser", cardType: 0 },
    { cardItem: "ServiceDeskPerformance", cardType: 0 }
];

export const PreVersionElevenMigration = [
    "2022.1.0.1",
    "2022.1.0.2",
    "2022.1.0.3",
    "2022.1.0.4",
    "2022.1.0.5",
    "2022.1.0.6",
    "2022.1.0.7",
    "2022.1.0.8",
    "2022.1.0.9",
    "2022.1.0.10"
];
