import * as React from "react";
import { Col, Row } from "../../common/ui";
import { Doughnut, Pie } from "react-chartjs-2";
import GetCardTheme from "./SharedCardFunctions";
import BlankCard from "./BlankCard";

type CardPieProps = {
    title: string,
    children: JSX.Element,
    cardID: string,
    graphType: "pie" | "doughnut",
    data: string[],
    labels: string[],
    pieColours: string[],
    type: string[]
    getDone: boolean,
    otherData: string[],
    otherDataTitle: string[],
    activatePopup: () => void,
    openHelpPopup: () => void,
    custID: string,
    startDate: Date,
    endDate: Date,
    isCSE: boolean,
    customID: string
}
type CardPieState = {
    chartFontColour: string,
    chartKey: number
}

export default class CardPie extends React.PureComponent<CardPieProps, CardPieState> {
    state = {
        chartFontColour: GetCardTheme(),
        chartKey: 0
    }
    componentDidMount() {
        window.addEventListener("themeToggled", this.UpdateTheme);
    }
    componentWillUnmount() {
        window.removeEventListener("themeToggled", this.UpdateTheme);
    }
    componentDidUpdate(prevProps: CardPieProps) {
        if (prevProps.data !== this.props.data) {
            this.setState({ chartKey: this.state.chartKey + 1 });
        }
    }
    UpdateTheme = () => {
        const chartFontColour = GetCardTheme();
        this.setState({ chartFontColour });
    };
    PieChartData = () => {
        return {
            labels: this.props.labels,
            datasets: [
                {
                    data: this.props.data,
                    backgroundColor: this.props.pieColours,
                    borderColor: this.props.pieColours,
                    borderWidth: 1
                }
            ]
        };
    };
    PieChartOptions = () => {
        return {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    labels: {
                        font: { size: 12 },
                        color: this.state.chartFontColour
                    },
                    title: { color: this.state.chartFontColour }
                }
            }
        };
    };
    render() {
        var Chart = <></>;
        var otherDataSize = "0px";
        if (this.props.getDone) {
            if (this.props.graphType === "pie") {
                Chart = <Pie key={`pie ${this.state.chartKey}`} data={this.PieChartData} options={this.PieChartOptions()} />;
            } else {
                Chart = <Doughnut key={`doughnut ${this.state.chartKey}`} data={this.PieChartData} options={this.PieChartOptions()} />;
            }
        }
        switch (this.props.otherData.length) {
            case 4:
                otherDataSize = "22px";
                break;
            default:
                otherDataSize = "24px";
                break;
        }
        return (
            <BlankCard
                activatePopup={this.props.activatePopup}
                title={this.props.title}
                openHelpPopup={this.props.openHelpPopup}
                cardID={this.props.cardID}
                custID={this.props.custID}
                isCSE={this.props.isCSE}
                customID={this.props.customID}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
            >
                {this.props.children}
                <Row className="dash-content-row">
                    <Col center className="dash-content-col" style={{ height: "136px" }}>
                        {this.props.otherData.map((data, index) => {
                            if (this.props.otherData.length > 1) {
                                return (
                                    <div
                                        key={this.props.otherDataTitle[index] + data + index}
                                        className={"main " + this.props.type[index]}
                                        style={{ fontSize: otherDataSize }}
                                    >
                                        {data}: {this.props.otherDataTitle[index]}
                                    </div>
                                );
                            } else {
                                return (
                                    <div key={this.props.otherDataTitle[index] + data + index}>
                                        <div className={"main " + this.props.type[index]}>
                                            {data}
                                            <br />
                                        </div>
                                        <p>{this.props.otherDataTitle[index]}</p>
                                    </div>
                                );
                            }
                        })}
                    </Col>
                    <Col className="dash-content-col" style={{ height: "150px", width: "300px" }}>
                        {Chart}
                    </Col>
                </Row>
            </BlankCard>
        );
    }
}
